export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url?.replace('-', '+').replace('_', '/');
    let data = getAtob(base64) ? getAtob(base64) : null
    let details = {}
    details = {...JSON.parse(data)}
    return details;
}

function getAtob(data) {
    try {
        return window.atob(data);
    } catch(e) {
        return false
    }
}