import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar, Pie, Doughnut, Line } from 'react-chartjs-2';

import ReportsRepo from '../repository/Reports'
import { sendNotify } from '../helper/notification';
import { Divider, Modal } from 'antd';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Reports() {

  const [reportVal, setReportVal] = useState({});
  const [trackidList, setTrackIdList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTrackIds, setSelectedTrackIds] = useState([]);
  const [lineData, setLineData] = useState({});
  useEffect(() => {
    getAllReports();
    getMonthTrend();
  }, []);

  const getAllReports = async () => {
    setTrackIdList([])
    ReportsRepo.getAllReports().then(response => {
      if (response) {
        if (response?.error) {
          sendNotify('error', response.message)
        } else {
          let items = response;
          let dataset = [];
          let datalabel = Object.keys(items);
          let trackId = [];
          Object.keys(items).map(item => {
            dataset.push(items[item]["count"])
            trackId.push(items[item]["trackid"])
          })
          let val = {
            labels: datalabel,
            datasets: [
              {
                label: '# of TrackIds',
                data: dataset,
                backgroundColor: [
                  'rgba(75, 192, 192, 0.7)',
                  'rgba(54, 162, 235, 0.7)',
                  'rgba(255, 206, 86, 0.7)',
                ],
                borderColor: [
                  'rgba(75, 192, 192, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
              },
            ],
          }
          setReportVal(val);
          setTrackIdList(trackId);
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
    })
  }

  const getTrackIdLists = (selectedIndex) => {
    setSelectedTrackIds(trackidList[selectedIndex]);
    setIsModalOpen(true);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const bgClr = [
    'rgba(75, 192, 192, 0.7)',
    'rgba(54, 162, 235, 0.7)',
    'rgba(255, 206, 86, 0.7)',
  ]

  const getMonthTrend = async () => {
    ReportsRepo.getMonthTrend().then(response => {
      if (response) {
        if (response?.error) {
          sendNotify('error', response.message)
        } else {
          let dataset = []
          let labelset = response.data.Month;
          console.log(response.data)
          Object.keys(response.data).map((dt, i) => {
            if (dt != "Month") {
              dataset.push({
                label: dt,
                data: response.data[dt],
                backgroundColor: bgClr[i]
              })
            }
          })
          let barData = {
            labels: labelset,
            datasets: dataset,
          };
          setLineData(barData)
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
    })
  }

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 14,
            family: 'Outfit-Regular'
          }
        }
      },
      title: {
        display: false,
      },
    },
  };


  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const barData = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => Math.random({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => Math.random({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(53, 162, 235, 0.7)',
      },
    ],
  };

  const pieData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(153, 102, 255, 0.7)',
          'rgba(255, 159, 64, 0.7)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <section className='mt-md-3'>
      <div className='container-fluid px-0'>
        <div className="page-header">
          <h1>Reports</h1>
        </div>
        <div className="row gy-4">
          <div className="col-md-4">
            <div className="chart-box">
              <h2>Reports Trend</h2>
              {/* <p>Overall reports trend by Track Ids.</p> */}
              {Object.keys(reportVal).length ?
                <Doughnut data={reportVal}
                  options={{
                    onClick: function (evt, element) {
                      getTrackIdLists(element[0].index)
                    }
                  }}
                />
                : ""
              }
            </div>
          </div>
          <div className="col-md-8">
            <div className="chart-box">
              <h2>Monthly Trend</h2>
              {/* <p>Monthly reports trend by Track Ids.</p> */}
              {Object.keys(lineData).length ?
                <Line options={barOptions} data={lineData} />
                : ""}
            </div>
          </div>
          {/* <div className="col-md-8">
            <div className="chart-box">
              <h2>Summary</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <Bar options={barOptions} data={barData} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="chart-box">
              <h2>Summary</h2>
              <p>Lorem ipsum dolor sit amet consectetur.</p>
              <Pie data={pieData} />
            </div>
          </div> */}
        </div>
      </div>
      <Modal
        title="NL-Track Ids"
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        style={{
          top: 20
        }}
      >
        <div
          style={{
            height: "75vh",
            overflow: "auto"
          }}>
          {selectedTrackIds && selectedTrackIds.map((id, i) => (
            <tr>
              <td>{i + 1}:</td>&ensp;
              <td>{id}</td>
            </tr>
          ))}
        </div>
      </Modal>
    </section>
  )
}
