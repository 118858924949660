import Repository, { apiUrl } from './index';

class ReportRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getAllReports() {
        let url = `${apiUrl}/getChartData`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }

    async getMonthTrend() {
        let url = `${apiUrl}/monthtrend`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }

}

export default new ReportRepository();