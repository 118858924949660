import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from "./store/configureStore";
import { Provider } from "react-redux";

import './assets/css/gobal.css';
import 'antd/dist/reset.css';
import "yet-another-react-lightbox/styles.css";
import './assets/css/antd.customize.scss';
import './assets/css/bootstrap.min.css';
import './assets/css/style.scss';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

