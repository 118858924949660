import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';

import ImageZoomView from '../components/ImageZoomView';
import ManualForm from '../components/ManualForm';
import SendEmailForm from '../components/SendEmailForm';

import { sendNotify } from '../helper/notification';

import TrackRep from '../repository/TrackRep';

export default function Process() {
  let { id, status } = useParams();
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    // console.log(id, status);
    getImages(id, status)
  }, []);

  const getImages = (id, status) =>{
    setImageLoading(true)
    TrackRep.getProcessedData(id, status).then(response => {
        if (response) {
          setImageLoading(false)
          if (response?.error) {
            sendNotify('error',response.message);
            setImages([])
          } else {
            // console.log(response);
            setImages(response)
          }
        }
      })
        .catch(error => {
          sendNotify('error',error)
        })
  }

  const backToList = () =>{
    navigate(`/app/track`)
  }

  return (
    <section className='mt-md-3'>
      <div className='container-fluid px-0'>
        <div className="page-header">
          <h1>Process</h1>
          <button className='primary-btn' onClick={backToList}><i className="far fa-backward"></i> Back</button>
        </div>
        <div className="page-card">
          {imageLoading ?
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <Skeleton.Image active className='custom-image-loader' />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <Skeleton.Image active className='custom-image-loader' />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <Skeleton.Image active className='custom-image-loader' />
              </div>
            </div>
            :
            <ImageZoomView
              images={[
                {
                  src: `data:image/jpeg;base64,${images?.photo}`
                },
                {
                  src: `data:image/jpeg;base64,${images?.front}`
                },
                {
                  src: `data:image/jpeg;base64,${images?.back}`
                }
              ]}
            />
          }
          <div className="row mt-3 mt-md-4">
            <div className="col-md-5 mb-4 mb-md-0">
              <ManualForm trackId={id} />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <SendEmailForm trackId={id} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
