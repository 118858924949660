// Auth
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";

//Private
import Track from "../pages/track";
import Process from "../pages/process";
import Reports from "../pages/reports";

import NotFound from "../pages/not-found";

const routes = [
  {
    name: "Login",
    path: "/",
    icon: "",
    component: <Login />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  },
  {
    name: "Login",
    path: "/login",
    icon: "",
    component: <Login />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  },
  {
    name: "Register",
    path: "/register",
    icon: "",
    component: <Register />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  },
  {
    name: "Track",
    path: "track",
    icon: "fa-file-search",
    component: <Track />,
    type: 'private',
    permissions: [],
    showInMenu: true,
  },
  {
    name: "Process",
    path: "process/:id/:status",
    icon: "fa-file-search",
    component: <Process />,
    type: 'private',
    permissions: [],
    showInMenu: false,
  },
  {
    name: "Reports",
    path: "reports",
    icon: "fa-chart-bar",
    component: <Reports />,
    type: 'private',
    permissions: [],
    showInMenu: true,
  },
  {
    name: "Error 404",
    path: "*",
    icon: "",
    component: <NotFound />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  }
];

export default routes;
