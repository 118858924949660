import React, { useEffect, useState } from 'react'
import { Table, Tag } from 'antd';
import { useNavigate } from "react-router-dom";

import { sendNotify } from '../helper/notification'
import TrackRep from '../repository/TrackRep'

export default function Track() {
  const navigate = useNavigate();

  const [trackLoading, setTrackLoading] = useState(false)
  const [trackList, setTrackList] = useState([])
  const [searchID, setSearchID] = useState('')

  useEffect(() => {
    getTracks()
  }, []);

  const openTrack = (id, status) => {
    // console.log(id, status);
    navigate(`/app/process/${id}/${status}`)
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'key',
    },
    {
      title: 'Track ID',
      dataIndex: 'id',
    },
    {
      title: 'Current Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status === "PENDING"
            ? <Tag color={"gold"} key={status}>{status}</Tag> :
            status === "REJECTED" ?
              <Tag color={"red"} key={status}>{status}</Tag> :
              <Tag color={"green"} key={status}>{status}</Tag>}
        </>
      ),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: 300
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, { id, status }) => (
        <>
          {status === "PENDING"
            ? <button className="primary-btn" key={id} onClick={() => openTrack(id, status)}><i className="far fa-eye"></i> Process</button> :
            status === "APPROVED"
              ? <Tag color={"green"} key={status}>Workflow Completed</Tag>
              : <Tag color={"orange"} key={status}>{status}</Tag>
          }
        </>
      ),
    },
  ];

  const getTracks = () => {
    setTrackLoading(true)
    TrackRep.getTracks().then(response => {
      setTrackLoading(false)
      if (response) {
        if (response?.error) {
          sendNotify('error', response.message)
          setTrackList([])
        } else {
          let data = response?.data?.map((data, i) => (
            {
              key: i + 1,
              id: data._id,
              status: data.status,
              comment: data.reason,
              actions: data
            }
          ))
          setTrackList(data)
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
      setTrackLoading(false)
    })
  }

  const searchTrack = () => {
    if (searchID) {
      setTrackLoading(true)
      TrackRep.getDocStatus(searchID).then(response => {
        setTrackLoading(false)
        if (response) {
          if (response?.error) {
            sendNotify('error', response?.message)
            setTrackList([])
          } else {
            sendNotify('success', 'Fetch track Data')
            let data = [{
              key: 1,
              id: response?.data._id,
              status: response?.data.status,
              comment: response?.data.reason,
              actions: response?.data
            }]
            setTrackList(data)
          }
        }
      }).catch(error => {
        sendNotify('error', 'Failed to fetch Data')
        setTrackLoading(false)
      })
    } else {
      getTracks()
      sendNotify('error', 'Enter the track code to search.')
    }
  }

  return (
    <section className='mt-md-3'>
      <div className="page-header flex-md-row flex-column">
        <h1>Track</h1>
        <div className="custom-search mt-3 my-md-1">
          <input type="text" placeholder='Search by track code' onChange={(e) => setSearchID(e.target.value)} />
          <button onClick={searchTrack}><i className="far fa-search"></i></button>
        </div>
      </div>

      <Table columns={columns} dataSource={trackList} loading={trackLoading} pagination={{ className: 'custom-pagination' }} className='custom-table' />
    </section>
  )
}
