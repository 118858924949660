import React, { useEffect, useState } from 'react'
import { DatePicker, notification } from 'antd';
import { sendNotify } from '../helper/notification';

import TrackRep from '../repository/TrackRep';

export default function ManualForm(props) {

  // Note: Only add required fields to this object.
  let formShama = {
    card_id: '',
    dob: '',
    name: ''
  }

  const [formValues, setFormValues] = useState(formShama);
  const [errorObj, setErrorObj] = useState(formShama);
  const [loading, setLoading] = useState(false);

  const getDatePicker = (date, dateString) => {
    // console.log(date, dateString);
    getInputValue('dob', date)
  };

  const getInputValue = (field, value) => {
    let values = { ...formValues }
    let errors = { ...errorObj }
    values[field] = value
    setFormValues(values)
    if (value) {
      errors[field] = ''
      setErrorObj(errors)
    } else {
      errors[field] = `${field?.replace(/-|_/g, '')} was required`
      setErrorObj(errors)
    }
  }

  function checkEmpty() {
    let values = { ...formValues }
    let errors = { ...errorObj }
    let emptyArr = []
    for (var key in values) {
      if (values[key] === "" || errors[key] !== '') {
        emptyArr.push(key)
      }
    }
    return emptyArr;
  }

  function formValidation() {
    let errors = { ...errorObj }
    let empty = checkEmpty()
    if (empty?.length > 0) {
      empty.forEach((field) => {
        errors[field] = `${field?.replace(/-|_/g, ' ')} was required`
      })
    } else {
      return true
    }
    setErrorObj(errors)
    return false
  }

  const submitForm = () => {
    if (formValidation()) {
      setLoading(true)

      let payload = { ...formValues }
      payload['_id'] = props.trackId
      payload['status'] = "APPROVED"
      payload['reason'] = "Face Match with Data Extraction"
      payload['submmited_date'] = ""
      payload['process_time'] = ""

      // console.log(payload);
      TrackRep.applyManual(payload).then(response => {
        if (response) {
          setLoading(false)
          if (response?.error) {
            sendNotify('error', response.message)
          } else {
            sendNotify('success', "Approval successfully")
            setFormValues(formShama)
            setErrorObj(formShama)
          }
        }
      })
        .catch(error => {
          sendNotify('error', error)
        })
    } else {
      sendNotify('error', "Some form field(s) are missing!")
    }
  }

  return (
    <div className='custom-form'>
      <h4>Enter details manually</h4>
      <div className={`input-box req ${errorObj['card_id'] ? 'input-error' : ''}`}>
        <label htmlFor="clientName">Card ID <span className='err-meg'>{errorObj['card_id']}</span></label>
        <input type="text" name='card_id' id='card_id' placeholder='Enter Card ID' value={formValues['card_id']} onChange={(e) => getInputValue('card_id', e.target.value)} />
      </div>
      <div className={`input-box req ${errorObj['dob'] ? 'input-error' : ''}`}>
        <label htmlFor="poc">DOB <span className='err-meg'>{errorObj['dob']}</span></label> <br />
        <DatePicker onChange={getDatePicker} className='custom-datepicker bordered' value={formValues['dob']} placeholder='Enter Date of Birth' />
      </div>
      <div className={`input-box req ${errorObj['name'] ? 'input-error' : ''}`}>
        <label htmlFor="name">Name <span className='err-meg'>{errorObj['name']}</span></label>
        <input type="text" name='name' id='name' placeholder='Enter Name' value={formValues['name']} onChange={(e) => getInputValue('name', e.target.value)} />
      </div>
      <div className="d-flex justify-content-center">
        <button className='secondary-btn me-2' onClick={() => setFormValues(formShama)}><i className="far fa-eraser"></i> Clear</button>
        <button className={`primary-btn btn-green ${loading ? 'btn-loading' : ''} ms-2`} onClick={submitForm}>
          <i className={`far ${loading ? 'fa-spinner-third' : 'fa-paper-plane'}`}></i>
          Appoval</button>
      </div>
    </div>
  )
}
