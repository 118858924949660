import React, { useEffect, useState } from 'react'

import { sendNotify } from '../helper/notification';

import TrackRep from '../repository/TrackRep';

export default function SendEmailForm(props) {
  // Note: Only add required fields to this object.
  let formShama = {
    recipient_email: '',
    body: ''
  }

  const [formValues, setFormValues] = useState(formShama);
  const [errorObj, setErrorObj] = useState(formShama);
  const [loading, setLoading] = useState(false);

  var emailValidate = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const getInputValue = (field, value) => {
    let values = { ...formValues }
    let errors = { ...errorObj }
    values[field] = value
    setFormValues(values)
    if (value) {
      errors[field] = ''
      if (field == 'recipient_email') if (!emailValidate.test(value)) errors['recipient_email'] = 'Enter valid email address'
      setErrorObj(errors)
    } else {
      errors[field] = `${field?.replace(/-|_/g, ' ')} was required`
      setErrorObj(errors)
    }
  }

  function checkEmpty() {
    let values = { ...formValues }
    let errors = { ...errorObj }
    let emptyArr = []
    for (var key in values) {
      if (values[key] === "" || errors[key] !== '') {
        emptyArr.push(key)
      }
    }
    return emptyArr;
  }

  function formValidation() {
    let values = { ...formValues }
    let errors = { ...errorObj }
    let empty = checkEmpty()
    if (empty?.length > 0) {
      empty.forEach((field) => {
        if (values[field]) {
          if (field == 'recipient_email') if (!emailValidate.test(values['recipient_email'])) errors['recipient_email'] = 'Enter valid email address'
        } else {
          errors[field] = `${field?.replace(/-|_/g, ' ')} was required`
        }
      })
    } else {
      return true
    }
    setErrorObj(errors)
    return false
  }

  const submitForm = () => {
    if (formValidation()) {
      setLoading(true)

      let payload = { ...formValues }
      payload['subject'] = props.trackId

      // console.log(payload);
      TrackRep.sendMail(payload).then(response => {
        if (response) {
          setLoading(false)
          if (response?.error) {
            sendNotify('error', response.message)
          } else {
            sendNotify('success', "Email sent successfully")
            setFormValues(formShama)
            setErrorObj(formShama)
          }
        }
      }).catch(error => {
        sendNotify('error', error)
      })
    } else {
      if (errorObj['recipient_email']) {
        sendNotify('error', errorObj['recipient_email'])
      } else {
        sendNotify('error', "Some form field(s) are missing!")
      }
    }
  }

  return (
    <div className='custom-form'>
      <h4>Send email for re-submit</h4>
      <div className={`input-box req ${errorObj['recipient_email'] ? 'input-error' : ''}`}>
        <label htmlFor="clientName">Email <span className='err-meg'>{errorObj['recipient_email']}</span></label>
        <input type="email" name='recipient_email' id='recipient_email' placeholder='Enter email address' value={formValues['recipient_email']} onChange={(e) => getInputValue('recipient_email', e.target.value)} />
      </div>
      <div className={`input-box req ${errorObj['body'] ? 'input-error' : ''}`}>
        <label htmlFor="body">Body <span className='err-meg'>{errorObj['body']}</span></label> <br />
        <textarea name="body" id="body" placeholder='Enter email body content' rows={5} value={formValues['body']} onChange={(e) => getInputValue('body', e.target.value)} ></textarea>
      </div>
      <div className="d-flex justify-content-center">
        <button className='secondary-btn me-2' onClick={() => setFormValues(formShama)}><i className="far fa-eraser"></i> Clear</button>
        <button className={`primary-btn btn-red ${loading ? 'btn-loading' : ''} ms-2`} onClick={submitForm}>
          <i className={`far ${loading ? 'fa-spinner-third' : 'fa-paper-plane'}`}></i>
          Send Mail</button>
      </div>
    </div>
  )
}
