// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/Outfit-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../font/Outfit-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../font/Outfit-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../font/Outfit-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../font/Outfit-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../font/Outfit-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Outfit-Light';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: 'Outfit-Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
    font-family: 'Outfit-Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
    font-family: 'Outfit-SemiBold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
    font-family: 'Outfit-Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

@font-face {
    font-family: 'Outfit-Black';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}

:root{
    --primary: #2095f2;
    --secondary: #3d3d45;
    --background: #FAF9FF;
}

*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

body{
    font-family: 'Outfit-Regular' !important;
    background-color: var(--background) !important;
}

input:focus,
button:focus{
    outline: none !important;
}

input::placeholder,
textarea::placeholder{
    color: #bfbfbf !important;
}

a {
    text-decoration: none !important;
}


@media (max-width: 767px) {
    .w-sm-100{
        width: 100% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/gobal.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4CAAkC;AACtC;;AAEA;IACI,4BAA4B;IAC5B,4CAAmC;AACvC;;AAEA;IACI,6BAA6B;IAC7B,4CAAoC;AACxC;;AAEA;IACI,8BAA8B;IAC9B,4CAAqC;AACzC;;AAEA;IACI,0BAA0B;IAC1B,4CAAiC;AACrC;;AAEA;IACI,2BAA2B;IAC3B,4CAAkC;AACtC;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,wCAAwC;IACxC,8CAA8C;AAClD;;AAEA;;IAEI,wBAAwB;AAC5B;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;AACpC;;;AAGA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["@font-face {\r\n    font-family: 'Outfit-Light';\r\n    src: url(../font/Outfit-Light.ttf);\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Outfit-Medium';\r\n    src: url(../font/Outfit-Medium.ttf);\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Outfit-Regular';\r\n    src: url(../font/Outfit-Regular.ttf);\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Outfit-SemiBold';\r\n    src: url(../font/Outfit-SemiBold.ttf);\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Outfit-Bold';\r\n    src: url(../font/Outfit-Bold.ttf);\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Outfit-Black';\r\n    src: url(../font/Outfit-Black.ttf);\r\n}\r\n\r\n:root{\r\n    --primary: #2095f2;\r\n    --secondary: #3d3d45;\r\n    --background: #FAF9FF;\r\n}\r\n\r\n*{\r\n    padding: 0%;\r\n    margin: 0%;\r\n    box-sizing: border-box;\r\n}\r\n\r\nbody{\r\n    font-family: 'Outfit-Regular' !important;\r\n    background-color: var(--background) !important;\r\n}\r\n\r\ninput:focus,\r\nbutton:focus{\r\n    outline: none !important;\r\n}\r\n\r\ninput::placeholder,\r\ntextarea::placeholder{\r\n    color: #bfbfbf !important;\r\n}\r\n\r\na {\r\n    text-decoration: none !important;\r\n}\r\n\r\n\r\n@media (max-width: 767px) {\r\n    .w-sm-100{\r\n        width: 100% !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
