import React from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

//Layout
import AuthLayout from './layout/auth'
import BaseLayout from './layout/base'

import routes from './helper/routes';

import PrivateRoute from './helper/PrivateRoute';

import { checkLogged } from './store/auth/authSlice';

export default function App() {
  const dispatch = useDispatch()

  dispatch(checkLogged())

  return (
    <Router>
        <Routes>
          <Route element={<AuthLayout />}>
            {routes.filter(r=>r.type === 'public').map(route =>{
              return <Route element={route.component} key={route.path} path={route.path} />
            })}
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<BaseLayout />} path='/app'>
              {routes.filter(r=>r.type === 'private').map(route =>{
                return <Route element={route.component} key={route.path} path={route.path} />
              })}
            </Route>
          </Route>
        </Routes>
    </Router>
  )
}
