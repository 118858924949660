import React, { useState, useEffect } from 'react';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

export default function ImageZoomView(props) {
  let { images } = props;

  const [index, setIndex] = useState(-1);
  
  const [open, setOpen] = useState(false);
  const [maxZoomPixelRatio, setMaxZoomPixelRatio] = React.useState(5);
  const [zoomInMultiplier, setZoomInMultiplier] = React.useState(2);
  const [doubleTapDelay, setDoubleTapDelay] = React.useState(300);
  const [doubleClickDelay, setDoubleClickDelay] = React.useState(300);
  const [doubleClickMaxStops, setDoubleClickMaxStops] = React.useState(2);
  const [keyboardMoveDistance, setKeyboardMoveDistance] = React.useState(50);
  const [wheelZoomDistanceFactor, setWheelZoomDistanceFactor] =
    React.useState(100);
  const [pinchZoomDistanceFactor, setPinchZoomDistanceFactor] =
    React.useState(100);
  const [scrollToZoom, setScrollToZoom] = React.useState(false);

  const viewImage = (index) =>{
    setIndex(index)
    setOpen(true)
  }

  return (
    <div className="row">
      {images?.length > 0 && images?.map((image,index)=>{
        return(
          <div className="col-md-4 mb-3 mb-md-0" key={index} onClick={()=>viewImage(index)}>
            <div className="zoom-img">
              <img src={image.src} alt="" />
              <div className='zoom-layer'><i className="far fa-search-plus"></i></div>
            </div>
          </div>
        )
      })}
      <Lightbox
        index={index}
        controller={true}
        open={open}
        close={() => setOpen(false)}
        slides={images}
        plugins={[Zoom]}
        animation={{ zoom: 500 }}
        zoom={{
          maxZoomPixelRatio,
          zoomInMultiplier,
          doubleTapDelay,
          doubleClickDelay,
          doubleClickMaxStops,
          keyboardMoveDistance,
          wheelZoomDistanceFactor,
          pinchZoomDistanceFactor,
          scrollToZoom,
        }}
      />
    </div>
  );
}
